import styled from 'styled-components';


export const Section = styled.section`
 height: 100vh;
 width: 100%;
 background: linear-gradient(90deg, rgba(0,2,47,1) 0%, rgba(0,2,47,1) 0%, rgba(0,255,87,1) 150%);
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 overflow: hidden;
 position: relative;
 z-index: 2;


`;

export const ContentLogin = styled.div`
 height: 300px;
 width: 500px;
 background: rgb(255 255 255 / 14%);
 box-shadow: 0 8px 32px 0 rgb(255 255 255 / 17%);
 backdrop-filter: blur( 5.5px );
 -webkit-backdrop-filter: blur( 13.5px );
 border: 1px solid rgba( 255, 255, 255, 0.18 );
 border-radius: 20px;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 gap: 10px;


 .google-button{
    background-color: #fff;
    border: none;
    color: #00022F;
    border-radius: 50px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: .3s;
 }

 .google-button:hover{
    transform: scale(1.1);
 }

 @media (max-width: 768px){
   width: auto;
   margin: 10px;
   padding: 30px;
 }

`;

export const Header = styled.header`
height: 100px;
width: 100px;
border-radius: 0% 50% 50% 0%;
background-color: #fff;
position: absolute;
top: 0;
left: 0;
box-shadow: 0px 4px 6px 0px #00000036;
display: flex;
align-items: center;
justify-content: center;


 .icon-exit{
    font-size: 40px;
    color: #000;
    font-weight: 300;

 }

`;

export const Image = styled.img`
 height: 90px;
 width: auto;

`;

export const ImageBack = styled.img`
position: absolute;
left: -100px;
top: -210px;
height: 800px;
width: auto;
animation: flutuar 3s ease-in-out infinite;


@keyframes flutuar {
   0% {
       transform: translateY(0);
   }
   50% {
       transform: translateY(-20px); /* Ajuste conforme necessário */
   }
   100% {
       transform: translateY(0);
   }
}

`;

export const ImageBack2 = styled.img`
position: absolute;
right: -250px;
bottom: -250px;
height: 800px;
width: auto;
animation: flutuar 3s ease-in-out infinite;


@keyframes flutuar {
   0% {
       transform: translateY(0);
   }
   50% {
       transform: translateY(-20px); /* Ajuste conforme necessário */
   }
   100% {
       transform: translateY(0);
   }
}

`;

export const MenuExit = styled.div`
height: 100px;
width: 100%;
background: #fff;
display: flex;
justify-content: start;
align-items: center;
z-index: 999;


`;