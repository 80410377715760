import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../Components/Header/index';
import Footer from '../../Components/Footer/index';
import { useParams } from 'react-router-dom';
import { SectionBanner, ContentPost, TextPost, TitlePost } from './style';
import SupportButton from '../../Components/Support';

function SinglePost() {
  const { id } = useParams();
  const [comunicado, setComunicado] = useState({});
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const url = process.env.REACT_APP_URL;



  useEffect(() => {
    const fetchComunicado = async () => {
      try {
        const response = await axios.get(`${baseUrl}${id}/`);
        setComunicado(response.data);
      } catch (error) {
        console.error('Erro:', error);
      }
    };

    fetchComunicado();
  }, [id]);

  return (
    <>
      <Header />
      <SectionBanner>
        <img className="banner-top" src={comunicado.Banner} alt="Banner"></img>
        <div className='title-top'>
          <TitlePost>{comunicado.title}</TitlePost>
          <p>{comunicado.formatted_date}</p>
        </div>
      </SectionBanner>
      <ContentPost>
        <TextPost dangerouslySetInnerHTML={{ __html: comunicado.message }} />
      </ContentPost>
      <Footer />
    </>
  );
}

export default SinglePost;
