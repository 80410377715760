import { FooterContent } from './style'
import Logo from "../../Assets/logo2.png";


function Footer() {
  return (
    <>
    <FooterContent>
        <img src={Logo}></img>
    </FooterContent>
    </>
  );
}

export default Footer;
