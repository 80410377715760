import styled from 'styled-components';

export const ContentBanner = styled.section`
padding-left: 50px;
padding-right: 50px;

.legend-banner div{
    top: 40%;
}

@media (max-width: 768px){
    .legend-banner div{
        top: auto;
    }
}

img{
    border-radius: 20px;
}

h3{
    font-size: 50px;
    text-shadow: 2px 2px 4px rgb(0 0 0 / 75%);
}

p{
    text-shadow: 2px 2px 4px rgb(0 0 0 / 75%);
}


@media(max-width: 768px){
    padding-left: 10px;
    padding-right: 10px;

    h3{
        font-size: 16px;
    }
}


`;