import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './Pages/Home/App';
import Login from './Pages/Login/index';
import SinglePost from './Pages/SinglePost/index';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';



function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const ClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const handleGoogleLoginSuccess = (credentialResponse) => {
    console.log(credentialResponse);

    // Faça qualquer outra coisa que você precise fazer após o login bem-sucedido

    // Atualize o estado para autenticado
    setIsAuthenticated(true);
  };

  const handleGoogleLoginError = () => {
    console.log('Login Failed');
  };

  return (
    <GoogleOAuthProvider clientId = {ClientId}>
        <Router>
          <Routes>
            <Route
              path="/"
              element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
            />
            <Route
              path="/login"
              element={
                <Login
                  onGoogleLoginSuccess={handleGoogleLoginSuccess}
                  onGoogleLoginError={handleGoogleLoginError}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
            <Route path="/post/:id" element={<SinglePost />} />
          </Routes>
        </Router>
    </GoogleOAuthProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
