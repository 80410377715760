import { Menu, Options, ExitSite, ButtonLink, ContentMenu, SnackbarBox } from './style';
import Logo from "../../Assets/logo.png";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';



function Header() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true });
  };

  const handleClosee = () => {
    setState({ ...state, open: false });
  };

  const buttons = (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleClick({ vertical: 'top', horizontal: 'center' })}>
          Top-Center
        </Button>
      </Box>
      <Grid container justifyContent="center">
        <Grid item xs={6}>
          <Button onClick={handleClick({ vertical: 'top', horizontal: 'left' })}>
            Top-Left
          </Button>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Button onClick={handleClick({ vertical: 'top', horizontal: 'right' })}>
            Top-Right
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={handleClick({ vertical: 'bottom', horizontal: 'left' })}>
            Bottom-Left
          </Button>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Button onClick={handleClick({ vertical: 'bottom', horizontal: 'right' })}>
            Bottom-Right
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleClick({ vertical: 'bottom', horizontal: 'center' })}>
          Bottom-Center
        </Button>
      </Box>
    </React.Fragment>
  );

  return (
    <>
    <Menu>
    <Button onClick={handleShow}>
     <MenuIcon className='icon-menu'/>
    </Button>
      <Link to='/'><img src={Logo}></img></Link>
    </Menu>

    <Options>
      <Offcanvas show={show} onHide={handleClose} style={{width: 320}}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title >Mundo Tecler</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <ContentMenu>
            <Link to='/'>
              <ButtonLink><PublicOutlinedIcon />Mundo Tecler</ButtonLink>
            </Link>

              <ButtonLink style={{background:"rgb(193 193 193)", color:"#fff",}} onClick={handleClick({ vertical: 'top', horizontal: 'center' })}><DescriptionOutlinedIcon />Meu RH</ButtonLink>

 
              <ButtonLink style={{background:"rgb(193 193 193)", color:"#fff",}} onClick={handleClick({ vertical: 'top', horizontal: 'center' })}><PhotoLibraryOutlinedIcon />Registro de Eventos</ButtonLink>

    
              <ButtonLink style={{background:"rgb(193 193 193)", color:"#fff",}} onClick={handleClick({ vertical: 'top', horizontal: 'center' })}><ReceiptLongOutlinedIcon />Registro Fiscal</ButtonLink>
   
              <ButtonLink style={{background:"rgb(193 193 193)", color:"#fff",}} onClick={handleClick({ vertical: 'top', horizontal: 'center' })}><ContactSupportOutlinedIcon />FAQ</ButtonLink>

   
              <ButtonLink style={{background:"rgb(193 193 193)", color:"#fff",}} onClick={handleClick({ vertical: 'top', horizontal: 'center' })}><PersonOutlineOutlinedIcon  />Perfil</ButtonLink>

              <SnackbarBox>
                <Snackbar className='snack'
                  anchorOrigin={{ vertical, horizontal }}
                  open={open}
                  onClose={handleClosee}
                  message="Em breve mais novidades!"
                  key={vertical + horizontal}
                />
              </SnackbarBox>

            <ExitSite>
              <Link to='https://teclat.com.br/'>
                <ButtonLink><ExitToAppIcon />Voltar para o site</ButtonLink>
              </Link>
            </ExitSite>
            </ContentMenu>
          </Offcanvas.Body>
        </Offcanvas>
    </Options>




  
    </>
  );
}

export default Header;
