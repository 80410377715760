import styled from 'styled-components';

export const FooterContent = styled.footer`
height: max-content;
width: 100%;
padding: 50px 50px 50px 50px;
display: flex;
justify-content: left;
align-items: center;
background: #00022F;
margin-top: 50px;


img{
    height: 100px;
    width: auto;
}
`;

