import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { SectionNotices, Flag } from './style';


function Notices() {
  const [reportagens, setReportagens] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchReportagens = async () => {
      try {
        const response = await axios.get(baseUrl);
        setReportagens(response.data);
      } catch (error) {
        console.error('Erro:', error);
      }
    };

    fetchReportagens();
    const interval = setInterval(fetchReportagens, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <SectionNotices>
      <h1>Eventos e Comunicados</h1>
      <Row className='row'>
        {reportagens
          .filter(reportagem => reportagem.status === 'ATIVO') // Filtra os posts com status "ATIVO"
          .map(reportagem => (
            <Col xs={12} md="4" key={reportagem.id}>
              <li>
                <Card className="card" style={{ position: 'relative' }}>
                  <Flag>
                    <h3>{reportagem.announcement_type}</h3>
                  </Flag>
                  <Card.Img className="card-img" variant="top" src={reportagem.Banner}></Card.Img>
                  <Card.Body>
                    <Card.Title className="card-title">{reportagem.title}</Card.Title>
                    <Card.Text className="card-text">
                      <CalendarMonthOutlinedIcon /> {reportagem.formatted_date}
                    </Card.Text>
                    <Card.Text className="card-text">
                      {reportagem.description}
                    </Card.Text>
                    <Link to={`/post/${reportagem.id}`}>
                      <Button className="card-button">Ver mais</Button>
                    </Link>
                  </Card.Body>
                </Card>
              </li>
            </Col>
          ))}
      </Row>
    </SectionNotices>
  );
}

export default Notices;
