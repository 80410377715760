import styled from 'styled-components';


export const Banner = styled.section`
 height: max-content;
 width: 100%;
 background: #fff;
 z-index: 1;
 margin-top: 30px;
 margin-bottom: 30px;

`;
