import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ContentBanner } from './style';
import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import { Link } from 'react-router-dom';





function BannerCarousel() {
  const [reportagens, setReportagens] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  
  const fetchReportagens = () => {
      axios.get(baseUrl)
        .then(response => {
          setReportagens(response.data);
        })
        .catch(error => {
          console.error('Erro:', error);
        });
    };
  
    useEffect(() => {
      fetchReportagens(); // Primeira busca ao carregar o componente
  
      const interval = setInterval(() => {
        fetchReportagens(); // Busca de reportagens a cada 30 segundos
      }, 10000); // Tempo em milissegundos (30 segundos)
  
      return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
    }, []);

     // Filtra as reportagens para exibir apenas as que têm a variável booleana como true
    const reportagensParaBanner = reportagens.filter(reportagem => reportagem.pin === true);

    


  return (
    <ContentBanner>
        <Carousel>
        {reportagensParaBanner.map(reportagem => (
          
        <Carousel.Item key={reportagem.id}>
          <Link to={`/post/${reportagem.id}`}>
            <img
            className="d-block w-100"
            src={reportagem.Banner}
            alt="First slide"
            />
          </Link>
          <div className='legend-banner'>
            <Carousel.Caption>
              <h3>{reportagem.title}</h3>
              <p> {reportagem.description}</p>
              </Carousel.Caption>
          </div>
        </Carousel.Item>
          ))}
        </Carousel>
    </ContentBanner>
  );
}

export default BannerCarousel;
