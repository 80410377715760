import { Banner } from './style';
import Header from '../../Components/Header/index.js';
import Logo from "../../Assets/logo.png";
import BannerCarousel from "../../Components/Banner/index";
import Notices from '../../Components/Notices';
import FooterContent from '../../Components/Footer/index';
import Footer from '../../Components/Footer/index';
import SupportButton from '../../Components/Support/index.js';
import WelcomeModal from '../../Components/WelcomeModal/index.js';
import { useState, useEffect } from 'react';


function App() {
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  useEffect(() => {
    // Verificar se o usuário já visitou o site antes
    const hasVisitedBefore = localStorage.getItem('hasVisited');

    // Se o usuário nunca visitou antes, exibe o modal de boas-vindas
    if (!hasVisitedBefore) {
      setShowWelcomeModal(true);
      // Registra no armazenamento local que o usuário já visitou
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  const closeModal = () => {
    setShowWelcomeModal(false);
  };


  return (
    <>
    {showWelcomeModal && <WelcomeModal onClose={closeModal} />}
    <Header></Header>
    <Banner>
      <BannerCarousel></BannerCarousel>
    </Banner>
    <Notices></Notices>
    <FooterContent></FooterContent>
    </>
  );
}

export default App;
