import styled from "styled-components";

export const ButtonHelp = styled.a`
height: 80px;
width: 80px;
border-radius: 80px;
background-color: #0029FF;
color: #fff;
position: absolute;
bottom: 10px;
right: 10px;
display: flex;
justify-content: center;
align-items: center;
font-size: 50px;
cursor: pointer;
transition: all.3s;
&:hover{
    transform: scale(1.1);
}


svg{
    font-size: 50px;
}


`;