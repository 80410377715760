import styled from 'styled-components';

export const SectionNotices = styled.section`
height: max-content;
width: 100%;
margin-top: 50px;
padding: 50px 50px 50px 50px;


li{
    list-style: none;
    margin-top: 80px;
    

}

.card-img div{
    height: 5px;
    width: 15px;
    background-color: #000;
}

@media (max-width: 768px){
    li{
        margin-top: 40px;
    }
}

h1{
    font-size: 50px;
    font-weight: 700;
    color: #000;
}

@media(max-width: 768px){
    h1{
        font-size: 30px;
    }
}
`;

export const Flag = styled.div`
height: auto;
width: auto;
padding: 10px 20px 10px 20px;
background-color: #0029FF;
position: absolute;
right: 10px;
top: 15px;
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;

h3{
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;

}


`;


