import styled from 'styled-components';

export const SectionBanner = styled.section`
height: max-content;
width: 100%;
margin-top: 20px;
padding: 20px 100px 20px 100px;
display: flex;
justify-content: center;
align-items: center;
position: relative;


@media (max-width: 768px){
    padding: 20px 20px 20px 20px;
}

.banner-top{
    max-width: 100%;
    height: auto;
    width: auto;
    border-radius: 50px;
}

.title-top{
    position: absolute;
    bottom: -50px;
    left: 250px;
    background: #00022F;
    width: 600px;
    height: 200px;
    border-radius: 25px;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    padding-top: 50px;
    
}

@media(max-width: 1130px){
    .title-top{
        left: 130px;
        width: 500px;
        height: 170px;
        padding-bottom: 40px;
        padding-top: 40px 
    }
}

@media(max-width: 766px){
    .title-top{
        left: 50px;
        width: 400px;
        height: 150px;
        padding-bottom: 40px;
        padding-top: 40px 
    }
}

@media(max-width: 500px){
    .title-top{
        left: 34px;
        width: 300px;
        height: 100px;
        padding-bottom: 25px;
        padding-top: 32px;
    }
}






h1{
    color: #fff;
    text-align: left;
}

p{
    color: #fff;
    margin-bottom: 0;
}

@media (max-width: 500px){
    p{
        font-size: 8px;
    }
}


`;

export const ContentPost = styled.section`
height: max-content;
width: 100%;
padding: 200px 300px 100px 300px;
display: flex;
flex-direction: column;

@media (max-width: 1150px){
    padding: 100px 70px 20px 70px;
}

@media (max-width: 600px){
    padding: 100px 20px 20px 20px;
}


img{
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
}
`;

export const TitlePost = styled.h1`
font-size: 40px;
font-weight: 700;
color: #000;
text-align: center;
margin-bottom: 5px;

@media(max-width: 766px){
    font-size: 30px;

}

@media(max-width: 500px){
    font-size: 18px;

}


`;

export const TextPost = styled.p`
font-size: 20px;
font-weight: 500;
color: #000;
text-align: justify;




`;

