import styled from 'styled-components';

export const Menu = styled.section`
 height: 100px;
 width: 100%;
 background: #fff;
 border-bottom: 1px solid #00000024;
 display: flex;
 justify-content: start;
 padding-left: 50px;
 gap: 30px;
 align-items: center;
 z-index: 999;

 img{
    height: 50px;
    width: auto;
 }

 svg{
   font-size: 30px;
   color: #000;
   cursor: pointer;
   transition: .3s;

 }

 svg:hover{
  color: #fff;
 }

 button{
   background: transparent;
   border: none;
   border-radius: 100px;
 }

 button:hover{
   background: #00022F;
   border-radius: 200px;
   
 }

`;

export const Options = styled.div`
 width: 100%;
 background: transparent;

.title-menu{
   font-size: 10px;
}


 
`;

export const ButtonMenu = styled.a`
 width: 20px;
 height: 20px;
 background: #0029FF;
 padding: 20px;


`;

export const ButtonLink = styled.button`
 width: 100%;
 height: 50px;
 background: #fff;
 padding: 26px;
 border-radius: 50px;
 border: none;
 color: #000;
 display: flex;
 justify-content: start;
 align-items: center;
 font-size: 16px;
 gap: 20px;
 box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
 transition: .3s;
 margin-top: 20px;

 &:hover{
  background: #00022F;
  color: #fff;
 }

`;

export const ContentMenu = styled.div`
 width: 100%;
 height: 95%;
 background: #fff;
 padding-top: 0px;
 padding-left: 0px;
 padding-right: 0px;
 position: relative;


a{
  text-decoration: none;
}

.snack{
  background-color: #0029FF;
}
`;

export const ExitSite = styled.div`
 width: 100%;
 height: max-content;
 position: absolute;
 bottom: 0;


`;

export const SnackbarBox = styled.div`


div{
  background-color: #0029FF;
  border-radius: 10px;
}


`;








