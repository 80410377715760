import { useNavigate, Link } from 'react-router-dom';
import { Section, ContentLogin, TitleLogin, MenuExit, Header, Image, ImageBack, ImageBack2} from './style';
import { useGoogleLogin } from '@react-oauth/google';
import { useState, useEffect } from 'react';
import axios from 'axios';
import GoogleIcon from '@mui/icons-material/Google';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import Logo from '../../Assets/logo2.png';
import Back from '../../Assets/de.webp';
import Back2 from '../../Assets/de3.webp';



function Login({ onGoogleLoginSuccess, onGoogleLoginError, isAuthenticated, setProfile }) {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      onGoogleLoginSuccess(codeResponse.profile);
      
      // Certifique-se de que setProfile é uma função antes de chamá-la
      if (typeof setProfile === 'function') {
        setProfile(codeResponse.profile); // Passa as informações do perfil como prop
      }
      
      navigate('/');
    },
    
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <>
      {isAuthenticated ? (
        <p>Você já está autenticado. Redirecionando...</p>
      ) : (
        <Section>
          <ImageBack src={Back}></ImageBack>
          <ImageBack2 className='image2' src={Back2}></ImageBack2>

          <Header>
            <Link to='https://teclat.com.br/'>
             <ExitToAppRoundedIcon className='icon-exit'/>
            </Link>
          </Header>
          <ContentLogin>
            <Image src={Logo}></Image>
            <button className="google-button" onClick={() => login()}><GoogleIcon /> Entrar com o Google</button>
          </ContentLogin>
        </Section>
      )}
    </>
  );
}

export default Login;
